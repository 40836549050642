
.progress-wrapper {
  width: 100%;
}

.full-width {
  width: 100%;
  @media only screen and (max-height: 700px) {
    margin-top: 50px;
  }
  @media only screen and (max-height: 500px) {
    margin-top: 100px;
  }
  ::v-deep .progress-wrapper {
    padding: 0 30px;
    progress {
      height: 0.4rem;
      -webkit-transition: width 0.5s;
      transition: width 0.5s;
    }
  }
  .card.test {
    border: 0;
    border-top-width: 2px !important;
    border-top-style: solid;
    border-radius: 10px;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    box-shadow: none;
    padding: 10px 0;
    margin-bottom: 20px;
    &.shake {
      animation-name: shake;
      animation-duration: 1s;
    }
    .card-content {
      padding: 20px 30px;
      min-height: 200px;
      .question {
        margin-bottom: 20px;
      }
    }
    .card-footer {
      padding: 10px 10px 0 10px;
      border: 0;
      .card-footer-item {
        padding: 0;
        border: 0 !important;
        justify-content: flex-start;
        &.right {
          justify-content: flex-end;
        }
      }
    }
    &:hover {
      cursor: default;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-3px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(8px, 0, 0);
    }
  }
}
