
.question-word {
  text-emphasis-style: dot;
  text-emphasis-position: under left;

  &:hover {
    color: #fc7234;
    text-emphasis-color: #fc7234;
    cursor: pointer;
  }
}

.result-notification {
  min-height: 100px;
  padding: 10px 30px;
  margin-bottom: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
  font-size: 130%;

  &.correct {
    background: #ddffde;
    border-top: 3px solid #2DD634;
    color: #2DD634;
  }

  &.wrong {
    background: #ffe0e0;
    border-top: 3px solid #D62D2D;
    color: #D62D2D;
  }

  .level {
    width: 100%;
  }
}

.title.has-text-danger {
  color: #f14668 !important;
}

.correct-answer {
  margin-bottom: 20px;
}

textarea.answer {
  background: none !important;

  &:focus {
    border: 0 !important;
  }
}

#example-sentence {
  position: absolute;
  top: 10px;
  right: 10px;
}

#list-name {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(90, 90, 90);
  }
}
