
.answer-option {
  padding: 10px 15px;
  border-radius: 10px;
  background: #e2e2e2;
  font-weight: 600;
  width: fit-content;
  margin: 0 5px;
  &:hover {
    cursor: pointer;
    background: #efefef
  }
}
.answer-hidden {
  background: #e2e2e2;
  padding: 10px;
  display: inline-block
}
