
.container {
  width: 50%;
  transition: all 0.5s;
  &.full-height {
    min-height: 100vh;
  }
}
.stats {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
