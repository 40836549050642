
.stats {
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .stats {
    width: 100%;
  }
}

.title.has-text-danger {
  color: #f14668 !important;
}
.container {
  padding-top: 5%;
  padding-bottom: 20px;
}
.correct-answer {
  margin-bottom: 20px;
}
textarea.answer {
  background: none !important;
  &:focus {
    border: 0 !important;
  }
}

.field {
  padding: 12px;
  border: 2px solid transparent;
  background: #f2f1f4;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &.correct {
    border: 2px solid #9bc53d;
    font-weight: 600;
  }

  &.wrong {
    border: 2px solid#f14668;
    font-weight: 600;
  }
}

.question-word {
  text-emphasis-style: dot;
  text-emphasis-position: under left;
  &:hover {
    color: #fc7234;
    text-emphasis-color: #fc7234;
    cursor: pointer;
  }
}
