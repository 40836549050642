
.modal-card .modal-card-body {
  background: transparent;
}

.circle-image {
  background: var(--element-color);
  margin: 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  object {
    max-height: 128px;
  }
}
